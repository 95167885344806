import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Time scalar type is an instant in time which can be represented in multiple ways.
Accepted formats are:`}</p>
    <ul>
      <li parentName="ul">{`Timestamp string which conforms RFC3339 (subseconds are added if present).
i.e: `}<inlineCode parentName="li">{`2006-01-02T15:04:05Z07:00`}</inlineCode>{`, `}<inlineCode parentName="li">{`2020-02-17T16:15:34.823032+01:00`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Unix timestamp as an integer or floating point number (seconds since January 1, 1970 UTC). i.e: `}<inlineCode parentName="li">{`1569673381`}</inlineCode>{`.`}</li>
    </ul>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> Time</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      